import * as React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinode, faDocker, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faCodeMerge, faPresentation, faBrowser, faUniversalAccess, faVial, faRecycle } from '@fortawesome/pro-light-svg-icons'
import { ArrowRightIcon } from '@heroicons/react/24/outline'
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import LogoStripe from "../../images/logo-stripe-white.svg"
import LogoLinode from "../../images/logo-linode-white.svg"

const CapabilitiesPage = () => {
  return (
    <Layout>
        <Seo 
          title="Capabilities"
          description="Our knowledge, strengths and what we do best"
        />
        <div className="mx-4 mb-10 lg:max-w-screen-lg lg:mx-auto"> 
          <h1 className="text-5xl font-bold text-anomaly-red">Capabilities</h1>
        </div>

        <section 
        className="grid max-w-screen-lg mt-10 mb-20 text-center text-white md:gap-8 md:grid-cols-2 lg:mx-auto md:text-left">
          <div className="flex flex-col items-center justify-between p-8 md:items-start md:shadow-2xl md:rounded-3xl y-6 bg-stripe-light">
            <LogoStripe/>
            <h1 className="sr-only">Stripe</h1>
            <div className="flex flex-col items-center mt-4 md:items-start md:mt-16">
              <p>We offer discovery, design and implementation services around Stripe every growing set of features.</p>
              <Link className="flex items-center mt-2 font-bold" to="/capabilities/stripe">
                Learn how we can help 
                <ArrowRightIcon className="h-4 ml-2"/>
              </Link>
            </div>
          </div>
          <div className="flex flex-col items-center justify-between p-8 md:items-start md:shadow-2xl md:rounded-3xl y-6 lg:mx-auto bg-linode-light">
            <LogoLinode/>
            <h1 className="sr-only">Linode</h1>
            <div className="flex flex-col items-center mt-4 md:items-start md:mt-16">
              <p>We offer best practices advice to leverage Linode's transparent infrastructure to build deploy your workloads.</p>
              <Link className="flex items-center mt-2 font-bold" to="/capabilities/linode">
                Learn how we can help 
                <ArrowRightIcon className="h-4 ml-2"/>
              </Link>
            </div>
          </div>

        </section>

        <h2 className="text-4xl font-bold text-center text-anomaly-red">Design</h2>
        <h3 className="py-4 text-3xl font-medium text-center text-gray-500">From prototypes to fully functional interfaces.</h3>

        <div className="max-w-screen-lg mx-4 my-10 y-6 lg:mx-auto">
          <div className="flex flex-col justify-between mt-10 text-black lg:flex-row">
            <section className="mb-8 lg:pb-0 lg:w-1/3 lg:mr-10">
              <FontAwesomeIcon size="3x" className="mb-4 fill-current" icon={faPresentation} />
              <h3 className="mb-4 text-2xl font-semibold">Prototypes</h3>
              <p className="text-gray-500">
                The quickest way to validate your application is to design prototypes, 
                the space for design thinking to bring your idea to life.
              </p>
              <p className="mt-4 text-gray-500">
                Prototyping is an extremely cost-effective way of getting started and engaging your stakeholders. 
              </p>
            </section>
            <section className="mb-8 lg:pb-0 lg:w-1/3 lg:mr-10">
              <FontAwesomeIcon size="3x" className="mb-4 fill-current" icon={faBrowser} />
              <h3 className="mb-4 text-2xl font-semibold">Interfaces</h3>
              <p className="text-gray-500">
                We build highly responsive interfaces using modern web tooling and native mobile operating system technologies.
              </p>
              <p className="mt-4 text-gray-500">
                Our focus is to bring the natural native experiences that users expect on each platform.
              </p>
            </section>
            <section className="mb-8 lb:pb-0 lg:w-1/3">
              <FontAwesomeIcon size="3x" className="mb-4 fill-current" icon={faUniversalAccess} />
              <h3 className="mb-4 text-2xl font-semibold">Accessibility</h3>
              <p className="text-gray-500">
                Our accessibility-first approach to design ensures that our interfaces achieve the highest level of inclusion.</p>
              <p className="mt-4 text-gray-500">
                On the web we strive to achieve WCAG AAA compliance and follow human interface guidelines published by 
                Apple and Google for their mobile operating systems
              </p>
            </section>
          </div>
        </div>

        <h2 className="text-4xl font-bold text-center text-anomaly-red">Code</h2>
        <h3 className="py-4 text-3xl font-medium text-center text-gray-500">Polished implementation of our design with stellar habits.</h3>
        <div className="max-w-screen-lg mx-4 my-10 y-6 lg:mx-auto">
          <div className="flex flex-col justify-between mt-10 text-black lg:flex-row">
            <section className="mb-8 lg:pb-0 lg:w-1/3 lg:mr-10">
              <FontAwesomeIcon size="3x" className="mb-4 fill-current" icon={faRecycle} />
              <h3 className="mb-4 text-2xl font-semibold ">Reusability</h3>
              <p className="text-gray-500">
                APIs have always been at the heart of what we build. The approach brings reusability of business intelligence 
                yielding incremental returns from the engineering efforts. Applications by definition have several interfaces and 
                need to interoperate with other business systems.</p>
              <p className="mt-4 text-gray-500">
                This works outwards by our systems being able to integrate into 
                other platforms such as accounting or customer relationship management.
              </p>
            </section>
            <section className="mb-8 lg:pb-0 lg:w-1/3 lg:mr-10">
              <FontAwesomeIcon size="3x" className="mb-4 fill-current" icon={faCodeMerge} />
              <h3 className="mb-4 text-2xl font-semibold ">Native</h3>
              <p className="text-gray-500">
                We believe in choosing native technologies, it's what feels like home and performs as expected in the 
                deployed environment</p> 
              <p className="mt-4 text-gray-500">
                While the web remains the primary method of providing access to a system, we pride ourselves in 
                building really beautiful experiences on mobile platforms primarily iOS, iPadOS, watchOS, tvOS and Android.
              </p>
            </section>
            <section className="mb-8 lb:pb-0 lg:w-1/3">
              <FontAwesomeIcon size="3x" className="mb-4 fill-current" icon={faVial} />
              <h3 className="mb-4 text-2xl font-semibold">Quality Assurance</h3>
              <p className="text-gray-500">
                Automating our entire stack requires an extremely high level of quality assurance. With software systems 
                becoming so complex it’s no longer humanly possible to test and validate systems.
              </p>
              <p className="mt-4 text-gray-500">
                Automated unit and interface testing ensures that we pick up on any issues introduced during the development cycle. Our approach to software 
                is that it’s the infrastructure of today. 
              </p>
              <p className="mt-4 text-gray-500">                
                We apply the same principles to software that a structural engineer 
                applies to infrastructure. In today’s digital world, we cannot risk something not done to standard, 
                so we test and then we test again. 
              </p>
            </section>
          </div>
        </div>

        <h2 className="text-4xl font-bold text-center text-anomaly-red">Deploy</h2>
        <h3 className="py-4 text-3xl font-medium text-center text-gray-500">Infrastructure as code, so we can focus on code.</h3>
        <div className="max-w-screen-lg mx-4 my-10 y-6 lg:mx-auto">
          <div className="flex flex-col justify-between mt-10 text-black lg:flex-row">
            <section className="mb-8 lg:pb-0 lg:w-1/3 lg:mr-10">
              <FontAwesomeIcon size="3x" className="mb-4 fill-current" icon={faDocker} />
              <h3 className="mb-4 text-2xl font-semibold ">Containerised</h3>
              <p className="text-gray-500">
                Our approach utilises container technologies like Docker allowing us to lift and shift between 
                infrastructure providers. 
              </p>
              <p className="mt-4 text-gray-500">
                Our approach designs everything to be self-contained while being able to 
                take advantage of hosted solutions like managed databases.
              </p>
            </section>
            <section className="mb-8 lg:pb-0 lg:w-1/3 lg:mr-10">
              <FontAwesomeIcon size="3x" className="mb-4 fill-current" icon={faGithub} />
              <h3 className="mb-4 text-2xl font-semibold">Orchestration</h3>
              <p className="text-gray-500">
                Our process uses Terraform to define infrastructure as code, so we don’t provision anything by hand and 
                when your application is handed over, you can play the entire journal back to get a handle on the build.
              </p>
            </section>
            <section className="mb-8 lb:pb-0 lg:w-1/3">
              <FontAwesomeIcon size="3x" className="mb-4 fill-current" icon={faLinode} />
              <h3 className="mb-4 text-2xl font-semibold">Partnerships</h3>
              <p className="text-gray-500">
                We’ve built relationships over the years so we can focus on what we do best - software engineering.
              </p>
              <p className="mt-4 text-gray-500">
                We partner with infrastructure and payment providers to build the best workflows for our customers. 
              </p>
              <p className="mt-4 text-gray-500">
                Our abilities come from a combination of experience, teamwork and a deep understanding of fundamentals. 
              </p>
            </section>
          </div>
        </div>

    </Layout>
 );
};
export default CapabilitiesPage
